
















































































































































































































































































































































































































































































































































































































import Vue from 'vue';
// @ts-ignore
import xss from 'xss';
import _ from 'lodash';
import moment from 'moment';
import { blackList } from '@/util/blackFair';
// import Evaluation from './component/evaluation.vue';
// import PayDesposit from './component/payDeposit.vue';
// import OrderInfo from './component/orderInfo.vue';
import { mapState } from 'vuex';
import GoodsDetail from './component/goodsDetail.vue';
import NewDetailItem from './component/newDetail.vue';
import Booth from './component/booth1.vue';
// import Route from './component/Route.vue';
import TopBg from './component/detailTopBg.vue';
// import ServerStep from './component/ServerStep.vue';
// import Subsidy from './component/subsidy.vue';
import { initAliplayer } from './player';
// import NewItem from './component/newItem.vue';
import JuMap from '~/components/Map/index.vue';
import {
  bucketCdnHost,
  bucketHost,
  getContent,
  MINI_HOST,
  OSS_BUCKET_URL,
  prefixZero,
  sleep,
} from '~/util/common';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import 'swiper/css/swiper.min.css';
import 'vue-photo-preview/dist/skin.css';

const handleScrollDebounce = () => {};
let resetNum: number = 0;

// 展会顾问
const serviceList: any[] = [
  {
    id: 1,
    name: '胡经理',
    desc: '六年展会服务经验',
    level: '金牌项目经理',
    avatar: require('~/assets/images/hu.webp'),
    wechat: require('~/assets/images/wechat1.jpg'),
    tel: '400-099-0060 转 3',
    // chat: 'hu',
    chat: 'JavaScript:KS.openDirChatWin()',
  },
  {
    id: 2,
    name: '吴经理',
    desc: '三年展会服务经验',
    level: '好评项目经理',
    avatar: require('~/assets/images/wu.webp'),
    wechat: require('~/assets/images/wechat2.jpg'),
    tel: '400-099-0060 转 2',
    chat: '//wpa.qq.com/msgrd?v=3&uin=61882907&site=在线客服&menu=yes',
    // chat: 'wu',
  },
  {
    id: 3,
    name: '许经理',
    desc: '五年展会服务经验',
    level: '好评项目经理',
    avatar: require('~/assets/images/xu.webp'),
    wechat: require('~/assets/images/wechat3.jpg'),
    tel: '400-099-0060  转 1',
    chat: '//wpa.qq.com/msgrd?v=3&uin=61882907&site=在线客服&menu=yes',
    // chat: 'xu',
  },
];

const ticketService = [
  {
    id: 4,
    name: '鲁小姐',
    desc: '专业展会门票咨询',
    level: '优质客服',
    avatar: require('~/assets/images/lu.webp'),
    wechat: require('~/assets/images/wechat4.jpg'),
    tel: '400-099-0060 转 2',
    // chat: 'lu',
    chat: 'JavaScript:KS.openDirChatWin()',
  },
];

const exhContentList = [
  {
    name: '展会时间',
    id: 'exh-time',
  },
  {
    name: '展品范围',
    id: 'exh-range',
  },
  {
    name: '获取门票',
    id: 'exh-ticket',
  },
  {
    name: '同期展会',
    id: 'exh-same',
  },
  {
    name: '展商信息',
    id: 'exh-info',
  },
  {
    name: '展馆场地',
    id: 'exh-hell',
  },
  {
    name: '参展商名录',
    id: 'exh-menu',
  },
  {
    name: '展会报告',
    id: 'exh-report',
  },
];

export default Vue.extend({
  layout: 'layout2',
  components: { JuMap, Booth, GoodsDetail, NewDetailItem, TopBg },
  // 校验params参数
  // validate(ctx) {
  //   const id = _.get(ctx, 'params.id', null);
  //   console.log(id);
  //   return /^\d+$/.test(id);
  // },
  filters: {
    dateFormat2(date: string) {
      return moment(new Date(date)).format('YYYY年MM月DD日');
    },
    newDateFormat(data: Record<string, any>) {
      if (data.startTime && data.endTime) {
        const startTime = moment(data.startTime).format('YYYY.MM.DD');
        const endTime = moment(data.endTime).format('MM.DD');
        return `${startTime}-${endTime}`;
      } else {
        return '时间待定';
      }
    },
    industry(data: string): string {
      try {
        return data.split(',')[0] + '展会';
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + '展会';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  async asyncData(ctx) {
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    let id = _.get(ctx, 'params.id', null);
    if (!id) {
      const reg = /\/exhibition\/(\d+)\.html/;
      const result = ctx.route.path.match(reg);
      if (result) {
        id = result[1];
      }
    }

    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.post(`/api/exhibition/list`, {
        locale: langApi,
        selectTimeType: 1,
        exhibitionId: id,
        exhibitionType: 'CURRENT', // 同期展会
        pageNum: 1,
        pageSize: 30,
      }),
      ctx.$axios.post(`/api/exhibition/list`, {
        locale: langApi,
        selectTimeType: 1,
        exhibitionId: id,
        exhibitionType: 'SERIES', // 系列展会
        pageNum: 1,
        pageSize: 30,
      }),
      ctx.$axios.get(`/api/exhibition/boothInfo/${id}`),
      ctx.$axios.get(`/api/ticket/query/${id}`),
    ]);

    const [detailRes, sameFairRes, logoFairRes, boothRes, ticketRes] = res;

    let detail: Record<string, any> = {};
    const industry: any[] = [];
    let industryId: any[] = [];
    let industryName: any[] = [];
    let isFocus = false; // 是否关注
    let remind = false; // 是否提醒
    if (detailRes.code === ResponseCode.SUCCESS) {
      detail = detailRes.data || {};
      detail.pavilionInfo.picture = detail.pavilionInfo.picture?.split(';');
      if (
        (!_.isNil(detail.online) && detail.online !== 1 && id !== '4') ||
        _.isNil(detailRes.data)
      ) {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
      industryId = [
        detail.industry1,
        detail.industry2,
        detail.industry3,
      ].filter((id) => !!id);
      industryName = detail.industry?.split(',') || [];
      industryName.forEach((name, index) => {
        industry.push({
          id: industryId[index],
          name,
        });
      });
      isFocus = detailRes.data?.isFocus === 1; // 1: 已关注 0: 未关注
      remind = detailRes.data.follow; // 1: 提醒 0: 未提醒
    } else {
      return ctx.error({
        message: 'Page not found',
        path: ctx.route.fullPath,
        statusCode: 404,
      });
    }

    // 同期展会
    let sameFair = {};
    if (sameFairRes.code === ResponseCode.SUCCESS) {
      sameFair = sameFairRes.data;
    }

    // 系列展会
    let logoFair = {};
    if (logoFairRes.code === ResponseCode.SUCCESS) {
      logoFair = logoFairRes.data;
    }
    // 展会价格
    let booth = {};
    if (boothRes.code === ResponseCode.SUCCESS) {
      booth = boothRes.data;
    }

    const otherRes: any[] = await Promise.all([
      ctx.$axios.post('/api/exhibition/list', {
        locale: langApi,
        selectTimeType: 1,
        industryId: _.get(industry, '[0].id', null), // 行业展会
        regionType: detail.regionType,
        pageNum: 1,
        pageSize: 8,
      }),
      ctx.$axios.post('/api/newsApi/homePageList', {
        industryId: _.get(industry, '[0].id', null), // 推荐资讯
        newsType: 1,
        pageNum: 1,
        pageSize: 8,
      }),
      ctx.$axios.post('/api/newsApi/homePageList', {
        // 最新资讯
        newsType: 1,
        pageNum: 1,
        pageSize: 8,
      }),
      ctx.$axios.post('/api/exhibition/page', {
        // 最新展会
        locale: langApi,
        pageNum: 1,
        pageSize: 8,
      }),
    ]);

    const [industryFairRes, newsRes, newNewsRes, newFairRes] = otherRes;

    // 行业展会
    let industryFair = {};
    if (industryFairRes.code === ResponseCode.SUCCESS) {
      industryFair = industryFairRes.data;
    }

    // 行业资讯
    let news = {};
    if (newsRes.code === ResponseCode.SUCCESS) {
      news = newsRes.data.records.map(
        (item: { newsId: any; viewUrl: any; newsName: any; views: any }) => ({
          newsId: item.newsId,
          viewUrl: item.viewUrl,
          newsName: item.newsName,
          views: item.views,
        })
      );
    }

    // 最新资讯
    let newNews = {};
    if (newNewsRes.code === ResponseCode.SUCCESS) {
      newNews = newNewsRes.data.records.map(
        (item: { newsId: any; viewUrl: any; newsName: any; views: any }) => ({
          newsId: item.newsId,
          viewUrl: item.viewUrl,
          newsName: item.newsName,
          views: item.views,
        })
      );
    }

    // 最新展会
    let newFair = {};
    if (newFairRes.code === ResponseCode.SUCCESS) {
      newFair = newFairRes.data.records.map(
        (item: { exhibitionId: any; exhibitionName: any; views: any }) => ({
          exhibitionId: item.exhibitionId,
          exhibitionName: item.exhibitionName,
        })
      );
    }
    let ticketPrice = {};
    if (ticketRes.code === ResponseCode.SUCCESS) {
      ticketPrice = ticketRes.data;
    }

    return {
      detail,
      industry,
      isFocus,
      remind,
      sameFair,
      logoFair,
      booth,
      industryFair,
      news,
      newNews,
      newFair,
      ticketPrice,
      attribution: `&copy; <span>${
        _.get(detail, 'pavilionInfo.pavilionName', null) ||
        _.get(detail, 'pavilionInfo.pavilionEnglishName', null)
      }</span>`,
      center: [
        _.get(detail, 'pavilionInfo.latitude', 0),
        _.get(detail, 'pavilionInfo.longitude', 0),
      ],
      isSdkReady: false, // 客戶sdk初始化判断
    };
  },
  data(): any {
    return {
      player: '',
      isLoading: true,
      videoVisible: false,
      OSS_BUCKET_URL,
      blackList,
      ticketService,
      exhContentList,
      detail: {},
      activeName: 'first',
      showTitle: true,
      switchValue: true,
      fraction: 3.7,
      current: '展会数据',
      showEvaluation: false, // 控制评价弹框
      showMore: false,
      sameSwiperOption: {
        slidesPerView: 4,
        spaceBetween: 10,
        autoplay: 3000, // l轮播间隔时间
        loop: false, //
        lazy: {
          // 懒加载
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOption: {
        slidesPerView: 3.2,
        spaceBetween: 10,
        autoplay: 3000, // l轮播间隔时间
        loop: false, // 是否自动轮播
      },
      serviceList: [],
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
    photos(): any[] {
      try {
        const newArr: any[] = [];
        if (this.detail.photos) {
          this.detail.photos.slice(1).map((item: { resourceUrl: any }) => {
            newArr.push(item.resourceUrl);
          });
        }
        return newArr;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return this.detail.pic;
      }
    },
    qrcode(): string {
      const { id } = this.$route.params;
      const openId = _.get(this.$store, 'state.global.openId', '');
      const tel = _.get(this.$store, 'state.global.user.userName', '');
      const content = `${MINI_HOST}?exhibitionId=${id}&openId=${openId}&tel=${tel}`;

      return content;
    },
    industryList(): string[] {
      if (this.detail.industry) {
        try {
          const industry = this.detail.industry?.split(',');
          return industry;
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    tips(): any[] {
      try {
        const { tips } = this.$data.ticketPrice;
        return JSON.parse(tips).slice(0, 2);
      } catch (error) {
        return [];
      }
    },
  },
  created(): void {
    this.getCountDown();
  },
  mounted(): void {
    // this.init();
    // 随机显示两个展会顾问
    const _serviceList = _.cloneDeep(serviceList);
    const _idx = _.random(0, 2);
    _serviceList.splice(_idx, 1);
    this.serviceList = _serviceList;

    // 展会详情电梯
    const first: any = document.getElementById('pane-first');
    const title: any = document.getElementById('title');

    if (first && title) {
      title.style.height = first.offsetHeight + 'px';
    }

    const sameFair = _.get(this, 'sameFair.records', []);
    const logoFair = _.get(this, 'logoFair.records', []);
    const _exhContentList = _.cloneDeep(exhContentList);
    if (sameFair.concat(logoFair).length === 0) {
      _exhContentList.splice(3, 1);
      this.exhContentList = _exhContentList;
    }

    // handleScrollDebounce = _.debounce(this.handleScroll, 150);
    //
    // window.addEventListener('scroll', handleScrollDebounce);
    // 视频
    // this.getPlayAuth();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener('scroll', handleScrollDebounce);
  },
  methods: {
    unescape,
    xss,
    getContent,
    moment,
    closeVideo() {
      this.player.dispose();
      this.videoVisible = false;
    },
    // 客服SDK初始化
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },
    // 点击打开聊天窗
    openkefu(chat: string) {
      if (chat) {
        if (this.isSdkReady) {
          window.ysf('open');
        }
      } else {
        window.open(chat);
      }
    },
    openVideo() {
      if (!this.isLogin) {
        return this.$login.show();
      }
      this.videoVisible = true;
      this.getPlayAuth();
    },
    async handleToPage(tag: string) {
      const exhibitionId = this.detail.exhibitionId;
      const token = this.$cookies.get('access_token');

      await this.$store.commit(
        'user/setOrigins',
        location.origin + this.$route.fullPath
      );
      await this.$store.commit('user/setIndustryId', this.industryId);

      switch (tag) {
        case '展位预订':
        case '展位':
          await this.$store.commit('user/setAction', 1);
          if (!token) {
            this.toLogin();
            break;
          }
          window.open(`/exhibition/apply/${exhibitionId}.html`, '_self');
          break;
        case '门票预订':
        case '门票':
          // if (this.isNoTicket === 1) {
          //   this.$message.warning('门票已售罄!');
          // } else {
          //   window.open(`/exhibition/ticket/${exhibitionId}.html`, '_blank');
          // }
          await this.$store.commit('user/setAction', 2);
          if (!token) {
            this.toLogin();
            break;
          }
          window.open(`/exhibition/ticket/${exhibitionId}.html`, '_blank');
          break;
        case '展商名录':
        case '会刊':
          await this.$store.commit('user/setAction', 3);
          if (!token) {
            this.toLogin();
            break;
          }
          window.open(`/periodical/${exhibitionId}.html`, '_blank');
          break;
      }
    },
    separator(numb: string) {
      const str = numb.toString().split('.');
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return str.join('.');
    },

    scrollTo(id: string) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    },
    // 获取开展倒计时
    getCountDown() {
      if (process.client) {
        if (moment() > moment(`${this.detail.endTime}T18:00:00`)) {
          this.countDown = '';
          return false;
        }
        this.timer = setInterval(() => {
          const startTime = moment(); // 开始时间
          const endTime = moment(`${this.detail.endTime}T18:00:00`).subtract(
            1,
            'days'
          ); // 结束时间
          const diff = endTime.diff(startTime); // 时间差
          const arr = [];
          // 格式化为需要的格式 这里是天时分秒
          const days = Math.floor(moment.duration(diff).asDays());
          arr.push(days < 10 ? prefixZero(days, 2) : days);
          arr.push(prefixZero(moment.duration(diff).hours(), 2));
          arr.push(prefixZero(moment.duration(diff).minutes(), 2));
          arr.push(prefixZero(moment.duration(diff).seconds(), 2));
          this.countDown = arr.toString().replace(/,/g, '');
        }, 1000);
      }
    },
    // isLogin
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    // 显示购买弹窗
    async showPay() {
      if (this.shouldLogin()) {
        const res = await this.$axios.get(
          `api/pay/queryOrderStatus/${this.detail.exhibitionId}/1`
        );
        if (res.code === ResponseCode.SUCCESS) {
          // 订单状态 1-未支付 2-已支付 3-已提交 4-已完成
          if (_.get(res, 'data.status', null) === 2) {
            this.showOrderInfo(res.data);
          } else {
            this.$refs.payDesposit.dialogVisible = true;
          }
        }
      }
    },
    // 显示填写购买信息框
    showOrderInfo(data: any) {
      if (this.shouldLogin()) {
        this.$refs.orderInfo.dialogVisible = true;
        this.$refs.orderInfo.orderData = data;
      }
    },
    // 显示评价弹窗
    showEva() {
      if (this.shouldLogin()) {
        this.$refs.evaluation.dialogVisible = true;
      }
    },
    // 滚动事件
    handleScroll() {
      const menuDomList = document.querySelectorAll('a[data-name]');
      const { scrollTop } = document.documentElement;

      const res = Array.from(menuDomList).reduce((current, next) => {
        const nextTop = this.getDisTop(next);
        const top = nextTop - scrollTop;
        if (top > 20) {
          return current;
        } else {
          return next;
        }
      }, menuDomList[0]);

      const name = res.getAttribute('data-name');
      this.setCurrent(name);
    },
    // 获取元素距离页面顶部的距离
    getDisTop(element: any) {
      let realTop = element.offsetTop;
      let parent = element.offsetParent;
      while (parent !== null) {
        realTop += parent.offsetTop;
        parent = parent.offsetParent;
      }
      return realTop;
    },
    // tabs 切换
    handleClick() {
      if (this.activeName === 'first') {
        this.showTitle = true;
      } else {
        this.showTitle = false;
      }
    },
    // 设置当前显示的titile
    setCurrent(title: string) {
      this.current = title;
    },
    // 控制进度条不显示百分比
    format() {
      return '';
    },
    // 展会提醒
    async handleRemind() {
      if (this.shouldLogin()) {
        const id = _.get(this, '$route.params.id', null);
        if (!/\d+/.test(id)) {
          return this.$message.error(`展会Id:${id}不合法!`);
        }
        const res: any = await this.$axios.post(
          `/api/exhibition-follow/follow`,
          { exhibitionId: id }
        );
        if (res.code === ResponseCode.SUCCESS) {
          // this.$toast(!e ? '取消通知成功!' : '添加通知成功!', 3000);
          this.$toast(this.remind ? '取消通知成功!' : '添加通知成功!', 3000);
          this.remind = !this.remind;
        }
      }
    },
    // 关注展会
    async handleFollowFair() {
      await this.$store.commit(
        'user/setOrigins',
        location.origin + this.$route.fullPath
      );
      await this.$store.commit('user/setAction', 4);
      await this.$store.commit('user/setIndustryId', this.detail.industry1);

      if (this.shouldLogin()) {
        const id = _.get(this, '$route.params.id', null);
        // const res = await this.$axios.get(`/api/user/exhibition/${id}`);
        const res: any = await this.$axios.post(
          `/api/exhibition-follow/follow`,
          { exhibitionId: id }
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.isFocus = !this.isFocus;
        }
      }
    },
    handleNoTicket() {
      this.$message.warning('门票已售罄!');
    },
    // 阅读会刊
    async readPeriodical() {
      const token = this.$cookies.get('access_token');
      if (!token) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );
        await this.$store.commit('user/setAction', 3);
        await this.$store.commit('user/setIndustryId', this.detail.industry1);

        this.toLogin();
        return;
      }
      this.$router.push(`/periodical/${this.$route.params.id}.html`);
    },
    // 门票预订
    async handleToTicket() {
      const token = this.$cookies.get('access_token');
      if (!token) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );
        await this.$store.commit('user/setAction', 2);
        await this.$store.commit('user/setIndustryId', this.detail.industry1);

        this.toLogin();
        return;
      }
      window.open(
        `/exhibition/ticket/${this.detail.exhibitionId}.html`,
        '_blank'
      );
    },
    // 申请展位
    async handleApply() {
      const token = this.$cookies.get('access_token');
      if (!token) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );
        await this.$store.commit('user/setAction', 1);
        await this.$store.commit('user/setIndustryId', this.detail.industry1);

        this.toLogin();
        return;
      }
      window.open(`/exhibition/apply/${this.$route.params.id}.html`, '_blank');
    },
    // 查看更多
    handleShowrticle() {
      this.showMore = !this.showMore;
      if (!this.showMore) {
        setTimeout(() => {
          this.$refs.serverStep.scrollIntoView(true);
        }, 300);
      }
    },
    // 获取playauth
    getPlayAuth() {
      if (process.server) return;
      this.$nextTick(async () => {
        const el = document.getElementById('J_prismPlayer');
        if (!el && resetNum < 10) {
          await sleep(1000);
          this.getPlayAuth();
          resetNum = resetNum + 1;
          return;
        }
        this.isLoading = false;
        const resourceUrl = _.get(this.detail, 'video.resourceUrl', '');
        if (!resourceUrl) return false;
        let vid;
        try {
          vid = JSON.parse(resourceUrl)[0];
        } catch (error) {
          if (process.client && 'fundebug' in window) {
            window.fundebug.notifyError(error);
          }
          console.error(error);
        }
        if (!vid) return;
        if (
          vid.includes('.mp4') ||
          vid.includes('.m3u8') ||
          vid.includes('.m4v')
        ) {
          this.player = initAliplayer(
            'mp4',
            vid,
            ''
            // this.$options.filters.cover(this.detail.pic)
          );
        } else {
          const res = await this.$axios.get(
            `/api/exhibition/getPlayAuth/${vid}`
          );
          if (res.code === ResponseCode.SUCCESS) {
            this.playauth = res.data;
            this.player = initAliplayer(
              'm3u8',
              vid,
              res.data
              // this.$options.filters.cover(this.detail.pic)
            );
          }
        }
      });
    },
    toLogin() {
      this.$login.show();
    },
  },
  head(): Record<string, any> {
    const {
      lunchTime,
      exhibitionName,
      exhibitionEnglishName,
      pavilionInfo,
      organizer,
      pic,
      industry = '',
      regionType,
    } = this.$data.detail;
    const { areaName, pavilionAddress } = pavilionInfo || {};
    const _year = lunchTime?.slice(0, 4);

    const _keyword = [];
    const nameEn = exhibitionEnglishName?.trim() || '';
    try {
      _keyword.push(nameEn);
      const industryList = industry?.split(',') || [];
      industryList.forEach((name: string) => {
        _keyword.push(`${areaName}${name}展`);
        _keyword.push(`${areaName}${name}展门票`);
        _keyword.push(`${areaName}${name}展参展商`);
        _keyword.push(`${areaName}${name}展展位图`);
        _keyword.push(`${areaName}${name}展价格`);
      });
    } catch (error) {
      console.error(error);
    }
    const keywords = _keyword.join(',');
    let cover = require('~/assets/logo/logo.png');
    try {
      cover = JSON.parse(pic)[0];
    } catch (error) {
      if (process.client && 'fundebug' in window) {
        window.fundebug.notifyError(error);
      }
      console.error(error);
      cover = require('~/assets/logo/logo.png');
    }
    let period = '会刊';
    if (regionType === '1') {
      period = '参展商';
    }
    // const titleName = nameEn?.length >= 20 ? '' : `_${nameEn}`;
    const title = `${exhibitionName}${_year}_门票_${period}_价格-聚展`;
    const description = `展会时间:${lunchTime},展会地点:${pavilionAddress},主办方:${organizer},聚展网提供展会门票和展位预订服务.`;
    const aliplayer =
      'https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css';
    return {
      title,
      meta: [
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'og:type',
          content: 'product',
        },
        {
          name: 'og:image',
          content: cover,
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: description,
        },
        {
          name: 'og:url',
          content: `https://www.jufair.com${this.$route.path}`,
        },
        {
          name: 'og:product:price',
          content: this.booth?.boothPrice1,
        },
        {
          name: 'og:product:category',
          content: `${industry}展会`,
        },
        {
          name: 'og:product:nick',
          content: `name=聚展网; url=https://www.jufair.com${this.$route.path}`,
        },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: aliplayer,
        },
      ],
      script: [
        {
          charset: 'utf-8',
          type: 'text/javascript',
          src: 'https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js',
          rel: 'prefetch',
        },
      ],
    };
  },
});
